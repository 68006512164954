import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { CircularProgress, InputLabel, TextField, Button } from "@material-ui/core";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import { showErrorMessage } from "../../actions/notification";

function Login(props) {
	const { intl } = props;
	const [loading, setLoading] = useState(false);
	const [loadingButtonStyle, setLoadingButtonStyle] = useState({
		paddingRight: "2.5rem",
		margin: "0 auto",
		marginRight: "10px",
	});

	const enableLoading = () => {
		setLoading(true);
		setLoadingButtonStyle({ paddingRight: "3.5rem" });
	};

	const disableLoading = () => {
		setLoading(false);
		setLoadingButtonStyle({ paddingRight: "2.5rem" });
	};

	return (
		<>
			{/* <div className="kt-login__head">
        <span className="kt-login__signup-label">
          Don't have an account yet?
        </span>
        &nbsp;&nbsp;
        <Link to="/auth/registration" className="kt-link kt-login__signup-link">
          Sign Up!
        </Link>
      </div> */}

			<div className="kt-login__body">
				<div className="kt-login__form">
					<div className="kt-login__title-2">
						<h3>
							<FormattedMessage id="AUTH.LOGIN.TITLE" />
						</h3>
					</div>

					<Formik
						initialValues={{
							email: "",
							password: "",
						}}
						validate={(values) => {
							const errors = {};

							if (!values.email) {
								// https://github.com/formatjs/react-intl/blob/master/docs/API.md#injection-api
								errors.email = intl.formatMessage({
									id: "AUTH.VALIDATION.REQUIRED_FIELD",
								});
							} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
								errors.email = intl.formatMessage({
									id: "AUTH.VALIDATION.INVALID_FIELD",
								});
							}

							if (!values.password) {
								errors.password = intl.formatMessage({
									id: "AUTH.VALIDATION.REQUIRED_FIELD",
								});
							}

							return errors;
						}}
						onSubmit={(values, { setStatus, setSubmitting }) => {
							enableLoading();
							setTimeout(() => {
								login(values.email, values.password, "cb")
									.then(({ data }) => {
										disableLoading();
										if (data.signal) {
											props.login(data.data);
										} else {
											setSubmitting(false);
											showErrorMessage(data.message);
											// setStatus(
											//   intl.formatMessage({
											//     id: "AUTH.VALIDATION.INVALID_LOGIN"
											//   })
											// );
										}
									})
									.catch((error) => {
										disableLoading();
										setSubmitting(false);
										setStatus(
											intl.formatMessage({
												id: "AUTH.VALIDATION.INVALID_LOGIN",
											})
										);
									});
							}, 1000);
						}}
					>
						{({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
							<form noValidate={true} autoComplete="off" className="kt-form-2" onSubmit={handleSubmit}>
								{status ? (
									<div role="alert" className="alert alert-danger">
										<div className="alert-text">{status}</div>
									</div>
								) : (
									<div className="kt-login__desc">
										User email and password to login <strong>TSE Portal</strong>.
									</div>
								)}

								<div className="form-group mt-16">
									<InputLabel>Email</InputLabel>
									<TextField
										variant="outlined"
										size="small"
										type="email"
										margin="normal"
										className="kt-width-full mt-0"
										name="email"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.email}
										helperText={touched.email && errors.email}
										error={Boolean(touched.email && errors.email)}
										autoFocus
									/>
								</div>

								<div className="form-group mt-16">
									<InputLabel>Password</InputLabel>
									<TextField
										variant="outlined"
										size="small"
										type="password"
										margin="normal"
										className="kt-width-full mt-0"
										name="password"
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.password}
										helperText={touched.password && errors.password}
										error={Boolean(touched.password && errors.password)}
									/>
								</div>

								<div className="kt-login__actions">
									<Link to="/auth/forgotPassword" className="kt-login__link-forgot-2">Forgot password?</Link>
									<Button
										variant="contained"
										color="primary"
										type="submit"
										startIcon={loading ? <CircularProgress style={{ width: "16px", height: "auto" }} color="inherit" /> : <AddCircleOutlineIcon />}
										disabled={isSubmitting}
										className="btn-signin"
									>
										Sign in
									</Button>
								</div>
								<div className="kt-login__actions"></div>
							</form>
						)}
					</Formik>
				</div>
			</div>
		</>
	);
}

export default injectIntl(connect(null, auth.actions)(Login));
