import checkPermission from "../../app/libs/permission";

export default {
	header: {
		self: {},
		items: [],
	},
	aside: {
		self: {},
		items: [
			{
				title: "Review Test",
				root: true,
				page: "dashboard",
				permission: "marker",
				pathname: [
					{
						content: 'Detail',
						path: '/detail-progress/:id',
					},
				],
				image: '/images/menu-icon/icon-18.svg',
			},
			{
				title: "Dashboard",
				root: true,
				page: "dashboard",
				translate: "MENU.DASHBOARD",
				permission: "dashboard.view",
				bullet: "dot",
				pathname: [
					{
						content: 'Dashboard',
						path: '/dashboard',
					},
					{
						content: 'Inquiry Management',
						path: '/inquiry/list',
					},
					{
						content: 'Inquiry Detail',
						path: '/inquiry/view/:id',
					},
				],
				image: '/images/menu-icon/icon-18.svg',
			},
			{
				title: "Course Management",
				root: true,
				image: '/images/menu-icon/icon-16.svg',
				bullet: "dot",
				permission: ["course", "course_assigned.view", "tse_course.view", "course_analytics.view"],
				submenu: [
					{
						title: "External Courses",
						page: "courses/list",
						permission: ["course_assigned.view"],
						pathname: [
							{
								content: 'External Courses',
								path: '/courses/list',
							},
							{
								content: 'External Courses',
								path: '/course/view/:id',
								breadcrumb: [
									{
										title: 'External Courses',
										link: '/courses/list',
									},
									{
										title: 'View Course',
										link: '',
									},
								],
							},
							{
								content: 'Attendee',
								path: '/course/attendeed/:id',
							},
							{
								content: 'Review Exam',
								path: '/course/:course/preview-exam/:exam/version/:version'
							},
							{
								content: 'Detail Response Exam',
								path: '/course/:id/user-data/:user_id/item/:progress_id'
							},
							{
								content: 'Response Exam',
								path: '/course/:id/user/:user_id'
							},
						],
					},
					{
						title: "Your Courses",
						page: "courses-cb/list",
						permission: ["tse_course.view"],
						pathname: [
							{
								content: 'Your Courses',
								path: '/courses-cb/list',
							},
							{
								content: 'Create Course',
								path: '/course/create',
							},
							{
								content: 'Exam Analytics',
								path: '/course/detail/:id/analytic-exam/:exam_id',
							},
							{
								content: checkPermission('tse_course.update') ? `Edit Course` : `Detail Course`,
								path: '/course/edit/:id',
							},
						],
					},
					{
						title: "Course Analysis",
						page: "course/analytics",
						permission: "course_analytics.view",
						pathname: [
							{
								content: 'Course Analytics',
								path: '/course/analytics',
							}
						],
					},
				],
			},
			{
				title: "Question Bank",
				root: true,
				image: '/images/menu-icon/icon-15.svg',
				page: "question-bank/list",
				permission: "question_bank.view",
				pathname: [
					{
						content: 'Question Bank',
						path: '/question-bank/list',
						breadcrumb: [
							{
								title: 'Question Bank',
								link: '',
							}
						],
					},
					{
						content: 'Create Question',
						path: '/question-bank/create',
						breadcrumb: [
							{
								title: 'List Question',
								link: '/question-bank/list',
							},
							{
								title: 'Create Question',
								link: '',
							},
						],
					},
					{
						content: checkPermission('question_bank.update')
							? `Edit Question`
							: `View Question`,
						path: '/question-bank/edit/:id',
						breadcrumb: [
							{
								title: 'List Question',
								link: '/question-bank/list',
							},
							{
								title: checkPermission('question_bank.update')
									? `Edit Question`
									: `View Question`,
								link: '',
							},
						],
					},
				],
			},
			{
				title: 'Course Builder',
				root: true,
				page: 'course-builder/list',
				permission: "course_builder.view",
				image: '/images/menu-icon/icon-14.svg',
				pathname: [
					{
						content: 'Course Builder',
						path: '/course-builder/list',
					},
					{
						content: 'Course Builder',
						path: '/course-builder/create',
					},
					{
						content: 'Course Builder',
						path: '/course-builder/edit/:id',
					},
					{
						content: 'Preview',
						path: '/course-builder/preview/:id',
					},
				],
			},
			{
				title: "Quiz Management",
				root: true,
				page: "quiz/list",
				permission: "exam.view",
				image: '/images/menu-icon/icon-13.svg',
				pathname: [
					{
						content: 'Quiz Management',
						path: '/quiz/list',
					},
					{
						content: 'Review Quiz',
						path: '/quiz/preview/:id',
					},
					{
						content: 'Create Quiz',
						path: '/quiz/create',
					},
					{
						content: 'Edit Quiz',
						path: '/quiz/edit/:id',
					},
				],
			},
			{
				title: "Program Management",
				root: true,
				page: "training/list",
				bullet: "dot",
				permission: "training.view",
				image: '/images/menu-icon/icon-12.svg',
				pathname: [
					{
						content: 'Program Management',
						path: '/training/list',
					},
					{
						content: 'Create Event',
						path: '/training/create',
					},
					{
						content: checkPermission('training.update') ? `Edit Event` : `Detail Event`,
						path: '/training/edit/:id',
					}
				],
			},
			{
				title: "Sale Management",
				root: true,
				bullet: "dot",
				permission: [
					"sale",
					"promotion.view",
					"order.view",
					"referral_code.view",
					"payment.view"
				],
				image: '/images/menu-icon/icon-6.svg',
				submenu: [
					{
						title: "Promotion Management",
						page: "promotion/list",
						permission: "promotion.view",
						pathname: [
							{
								content: 'Promotion Management',
								path: '/promotion/list',
							},
							{
								content: 'Create Promotion',
								path: '/promotion/create',
							},
							{
								content: 'Edit Promotion',
								path: '/promotion/edit/:id',
							},
						],
					},
					{
						title: "Order Management",
						page: "order/list",
						permission: "order.view",
						pathname: [
							{
								content: 'Order Management',
								path: '/order/list',
							},
							{
								content: 'Order Details',
								path: '/order/view/:id',
							}
						],
					},
					{
						title: "Payment",
						page: "payment",
						permission: "payment.view",
						badge: {
							type: "kt-badge--md kt-badge--rounded paymentStatusNew",
						},
						pathname: [
							{
								content: 'Payment Information',
								path: '/payment',
							},
							{
								content: 'Transfer Log',
								path: '/transfer-log',
							},
							{
								content: 'Update Subscription Plan',
								path: '/change-subscription-plan',
							},
							{
								content: 'Update Payment method',
								path: '/change-payment-method',
							},
							{
								content: 'Storage Details',
								path: '/storage-detail',
							},
							{
								content: 'Billing Activity',
								path: '/billing-activity',
							},
							{
								content: 'Choose your Subscription Plan',
								path: '/subscription-plan',
							},
						],
					},
					{
						title: "Referral Code",
						page: "referral/list",
						permission: "referral_code.view",
						pathname: [
							{
								content: 'Referral Code',
								path: '/referral/list',
							}
						],
					},
				],
			},
			// {
			// 	title: "Inquiry Management",
			// 	root: true,
			// 	icon: "flaticon-paper-plane",
			// 	page: "inquiry/list",
			// 	badge: {
			// 		type: "kt-badge--md kt-badge--rounded inquiryStatusNew",
			// 		value: 0,
			// 	},
			// 	permission: "inquiry.view",
			// },
			{
				title: "Mass Com",
				root: true,
				page: "mass-comm/list",
				permission: "mass_com.view",
				image: '/images/menu-icon/icon-4.svg',
				pathname: [
					{
						content: 'Mass Com',
						path: '/mass-comm/list',
					},
					{
						content: 'View Mass Email',
						path: '/mass-comm/view',
					},
					{
						content: 'New Mass Email',
						path: '/mass-comm/create',
					},
					{
						content: 'Edit Mass Email',
						path: '/mass-comm/edit/:id',
					},
					{
						content: 'Use Mass Email',
						path: '/mass-comm/use/:id',
					},
				],
			},
			{
				title: 'Reports',
				root: true,
				image: '/images/menu-icon/icon-3.svg',
				bullet: 'dot',
				permission: [
					'report.view',
					'sale_report.view',
				],
				submenu: [
					{
						title: "Course Report",
						page: "report",
						bullet: 'dot',
						permission: "report.view",
						pathname: [
							{
								content: 'Report',
								path: '/report',
							},
						],
					},
					{
						title: "Sales Report",
						page: "sales-report",
						bullet: 'dot',
						permission: "sale_report.view",
						pathname: [
							{
								content: 'Sales Report',
								path: '/sales-report',
							},
						],
					},
				],
			},
			{
				title: 'Setting',
				root: true,
				image: '/images/menu-icon/icon-1.svg',
				bullet: 'dot',
				permission: [
					'appearance',
					'property.view',
					'industry.view',
					'level.view',
					"user_mngt",
					"admin.view",
					"user.view",
					"marker.view",
					"role.view",
					"plan.view",
					"ce.view"
				],
				submenu: [
					{
						title: 'LMS Appearance Configuration',
						bullet: 'dot',
						permission: 'website.view',
						page: 'appearance',
						pathname: [
							{
								content: 'LMS Appearance Configuration',
								path: '/appearance',
							},
						],
					},
					{
						title: 'Course Categories',
						bullet: 'dot',
						permission: [
							'property.view',
							'industry.view',
							'level.view'
						],
						submenu: [
							{
								title: 'Industry',
								page: 'industry/list',
								permission: 'industry.view',
								pathname: [
									{
										content: 'Industry',
										path: '/industry/list',
									},
								],
							},
							{
								title: 'Level',
								page: 'level/list',
								permission: 'level.view',
								pathname: [
									{
										content: 'Level',
										path: '/level/list',
									},
								],
							},
						],
					},
					{
						title: 'User Management',
						bullet: 'dot',
						permission: [
							"user_mngt",
							"admin.view",
							"user.view",
							"marker.view",
							"role.view"
						],
						submenu: [
							{
								title: "Admin",
								page: "admin/list",
								permission: "admin.view",
								pathname: [
									{
										content: 'Admin Management',
										path: '/admin/list',
									},
									{
										content: 'Create Admin',
										path: '/admin/create',
									},
									{
										content: checkPermission('admin.update') ? `Edit Admin` : `View Admin`,
										path: '/admin/edit/:id',
									},
								],
							},
							{
								title: "Users",
								page: "users/list",
								permission: "user.view",
								pathname: [
									{
										content: 'User Management',
										path: '/users/list',
									},
									{
										content: 'Create User',
										path: '/users/create',
									},
									{
										content: checkPermission('user.update') ? `Edit User` : `View User`,
										path: '/users/edit/:id',
									},
								],
							},
							{
								title: "Role & Permission",
								page: "role/list",
								permission: "role.view",
								pathname: [
									{
										content: 'Role & Permissions Management',
										path: '/role/list',
									},
									{
										content: 'Create Role',
										path: '/role/create',
									},
									{
										content: 'Edit Role',
										path: '/role/edit/:id',
									},
								],
							},
							{
								title: "Markers",
								page: "marker/list",
								permission: "marker.view",
								pathname: [
									{
										content: 'Marker Management',
										path: '/marker/list',
									},
									{
										content: 'Invite Marker',
										path: '/marker/create',
									},
									{
										content: checkPermission("marker.view") ? `Edit Marker` : `Detail Marker`,
										path: '/marker/edit/:id',
									},
								],
							},
							{
								title: "Agent",
								page: "agent/list",
								permission: "agent.view",
								pathname: [
									{
										content: 'Agent Management',
										path: '/agent/list',
										breadcrumb: [
											{
												title: 'Agent Management',
												link: '',
											}
										],
									},
									{
										content: 'Invite Agent',
										path: '/agent/invite',
										breadcrumb: [
											{
												title: 'Agent Management',
												link: '/agent/list',
											},
											{
												title: 'Invite Agent',
												link: '',
											},
										],
									},
									{
										content: 'Edit Agent',
										path: '/agent/edit/:id',
										breadcrumb: [
											{
												title: 'Agent Management',
												link: '/agent/list',
											},
											{
												title: "Edit Agent",
												link: '',
											},
										],
									},
								],
							},
						],
					},
					{
						title: "Subscription Plan to CE",
						page: "plan/list",
						permission: ["plan.view"],
						pathname: [
							{
								content: 'Subscription Plan to CE',
								path: '/plan/list',
							},
							{
								content: 'Create Plan',
								path: '/plan/create',
							},
							{
								content: checkPermission("plan.update") ? `Edit Plan` : `View Plan`,
								path: '/plan/edit/:id',
							},
						],
					},
					{
						title: "TSE-CE Management",
						page: "corporate/list",
						permission: "ce.view",
						badge: {
							type: "kt-badge--md kt-badge--rounded ce-request",
							value: 0,
						},
						pathname: [
							{
								content: 'Competency Entity Management',
								path: '/corporate/list',
							},
							{
								content: 'Create Competency Entity',
								path: '/corporate/create',
							},
							{
								content: 'Edit Competency Entity',
								path: '/corporate-tab/edit/:id',
							},
							{
								content: 'Department Listing',
								path: '/department-tab/edit/:id',
							},
							{
								content: 'Course Listing',
								path: '/corporate-course-tab/edit/:id',
							},
							{
								content: 'Edit Competency Entity',
								path: '/corporate-user-tab/edit/:id',
							},
							{
								content: 'Create User',
								path: '/corporate-user-tab/create/:id',
							},
							{
								content: 'Edit User',
								path: '/corporate-user-row-tab/edit/:corporate_id/user/:id',
							},
							{
								content: 'Subscription Information',
								path: '/corporate-payment-tab/edit/:id',
							},
							{
								content: 'Storage Details',
								path: '/corporate-storage/:id',
							},
							{
								content: 'Billing Activity',
								path: '/corporate-billing/:id',
							},
							{
								content: 'Competency Entity Detail',
								path: '/corporate-tab/detail/:id',
							},
						],
					},
				],
			},
			{
				title: "Terms and Conditions",
				root: true,
				image: '/images/menu-icon/icon.svg',
				bullet: "dot",
				permission: "page.view",
				submenu: [
					{
						title: "Privacy policy",
						page: "policy",
						permission: "page.view",
						pathname: [
							{
								content: 'Privacy policy',
								path: '/policy',
							},
						]
					},
					{
						title: "Terms and Conditions",
						page: "condition",
						permission: "page.view",
						pathname: [
							{
								content: 'Terms and Conditions',
								path: '/condition',
							},
						]
					},
				],
			},
		],
	},
};
