
import { notification } from 'antd';
import makeRequest from "../../app/libs/request";

export const showSuccessMessage = (title = "Success", description = "") => {
  notification['success']({
    message: title,
    description: ''
  });
}
export const showSuccessMessageIcon = (title = "Success", description = "") => {
  notification['success']({
    message: title,
    description: ''
  });
}
export const showErrorMessage = (title = "Error", description = "") => {

  notification['error']({
    message: title,
    description: description,
  });
}

export const emitNoti = (message = "") => {
  makeRequest("post", `noti/push`, { message })
};
