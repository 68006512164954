/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";
import objectPath from "object-path";
import Header from "./header/Header";
import SubHeader from "./sub-header/SubHeader";
import HeaderMobile from "./header/HeaderMobile";
import AsideLeft from "./aside/AsideLeft";
import { useHistory } from "react-router-dom";
// import Footer from "./footer/Footer";
import ScrollTop from "../../app/partials/layout/ScrollTop";
// import StickyToolbar from "../../app/partials/layout/StickyToolbar";
import HTMLClassService from "./HTMLClassService";
import LayoutConfig from "./LayoutConfig";
import MenuConfig from "./MenuConfig";
import LayoutInitializer from "./LayoutInitializer";
import QuickPanel from "../../app/partials/layout/QuickPanel";
import KtContent from "./KtContent";
import "./assets/Base.scss";
import makeRequest from "../../app/libs/request";
import { notification, Button } from "antd";
import * as auth from "../../app/store/ducks/auth.duck";
import { emitNoti, showErrorMessage } from "../../app/actions/notification";
import moment from "moment";
import { getPlanData as getPermissionsCourseBuilder } from '../../app/libs/utils'

import { matchPath } from "react-router-dom";
import { getFCMToken, onMessageListener } from "../../app/config/firebase";

const htmlClassService = new HTMLClassService();

function Layout(props) {
	let { children, asideDisplay, subheaderDisplay, selfLayout, layoutConfig } = props;
	htmlClassService.setConfig(layoutConfig);
	// scroll to top after location changes
	// window.scrollTo(0, 0);
	const [listNotify, setListNotify] = useState([]);
	const [settingData, setSetting] = useState(false);
	const [showMenu, setShowMenu] = useState(false);
	const [user, setUser] = useState(false);
	const [cb_id, setCBId] = useState(null);
	const [dataUser, setDataUser] = useState();
	const [pageNoti, setPageNoti] = useState(0);
	const [notiList, setNotiList] = useState({
		list: [],
		totalActive: 0,
		total: 0,
	});
	const [reloadNoti, setReloadNoti] = useState();
	const history = useHistory();

	let callCloneData = 0

	const permissionsCourseBuilder = getPermissionsCourseBuilder(1, 62)

	const getCompanyRequest = (cb_id) => {
		makeRequest("get", `cb/getCompanyRequestOfCB`, { cb_id })
			.then(({ data }) => {
				if (data.signal) {
					const noti = data.data;
					let listClassCE = document.getElementsByClassName("ce-request");
					if (listClassCE[0]) {
						listClassCE[0].innerHTML = noti;
						if (noti === 0) {
							listClassCE[0].remove();
						}
					}
					if (noti > 0 && !listClassCE[0]) {
						const divInquiry = document.querySelector("[href='/corporate/list']");
						if (divInquiry && noti > 0)
							divInquiry.innerHTML += `<span class="kt-menu__link-badge"><span class="kt-badge kt-badge--md kt-badge--rounded ce-request">${noti}</span></span>`;
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getCountStatusNew = (cb_id, info) => {
		makeRequest("get", `inquiry/countStatusNew`, { cb_id })
			.then(({ data }) => {
				if (data.signal) {
					let listNoti = document.getElementsByClassName("inquiry-noti-number");
					if (listNoti[0]) {
						listNoti[0].innerHTML = data.data;
						if (data.data === 0) {
							listNoti[0].remove();
						}
					}
					if (data.data > 0 && !listNoti[0]) {
						const divInquiryNoti = document.querySelector('.link-inquiry .notification-icon-2');
						if (divInquiryNoti && data.data > 0) {
							divInquiryNoti.innerHTML += `<div class="border-radius-99 noti-number inquiry-noti-number" >${data.data}</div>`;
						}
					}
					let elm = document.getElementsByClassName("paymentStatusNew");
					if (info === "active") {
						if (elm[0]) {
							elm[0].remove();
						}
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getPlanData = () => {
		makeRequest("get", `plan/detailPlanFeature`, { type: "cb" })
			.then(({ data }) => {
				if (data.signal) {
					localStorage.setItem("planData", JSON.stringify(data.data));
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const openNotification = () => {
		const key = `open${Date.now()}`;
		const btn = (
			<Button
				type="primary"
				size="small"
				onClick={() => {
					notification.close(key);
					history.push("/payment");
				}}
			>
				Go
			</Button>
		);
		notification.open({
			message: "Notification",
			description: "Please set up your bank account to get paid.",
			btn,
			key,
		});
	};

	const getCloneData = async (id) => {
		const data = await makeRequest("get", `cb/cloneData`, { id }).then(async (data) => {
			if (data.data.code !== 200) {
				const portal_userID_clonedata = localStorage.getItem(`TSE_${id}_clonedata`);
				if (callCloneData < 10 && !portal_userID_clonedata) {
					callCloneData++
					getCloneData(id)
					return
				}
				emitNoti(`[TSE_${id}_clonedata][err:sidebar]: ${JSON.stringify(data.data.message)}`)
				return JSON.parse(portal_userID_clonedata);
			}

			await localStorage.setItem(`TSE_${id}_clonedata`, JSON.stringify(data));
			return data
		}).catch((err) => {
			console.log(err);
			emitNoti(`[TSE_${id}_clonedata][err:sidebar]: ${JSON.stringify(err.message)}`)
			const portal_userID_clonedata = localStorage.getItem(`TSE_${id}_clonedata`);
			return JSON.parse(portal_userID_clonedata);
		})
		return data
	}

	const getCBById = async (id, is_marker) => {
		const cloneData = await getCloneData(id)
		if (cloneData.data.signal) {
			const item = cloneData.data.data;
			setCBId(id);
			const unShowNoti = localStorage.getItem("unShowNoti");
			if (item.status !== 2 && item.stripe_account_status === "inactive" && !is_marker && !parseInt(unShowNoti)) {
				openNotification();
				localStorage.setItem("unShowNoti", id);
			}
			const setting = {
				type: "cb",
				item,
			};
			await localStorage.setItem("setting", JSON.stringify(setting));
			setSetting(setting);

			if (item.sub_domain !== "eamd") {
				getPlanData();
			} else {
				localStorage.removeItem("planData");
			}
			if (item.status === 1) {
				setShowMenu(true);
				setTimeout(() => {
					getCountStatusNew(id, item.stripe_account_status);
				}, 1000);
				getCompanyRequest(id);
			} else if (item.status === 0) {
				showErrorMessage("This company is inactive. Can not login.");
				props.logout();
			} else {
				setShowMenu(false);
				if (item.expire_month) {
					if (parseInt(item.exprie_year) < parseInt(moment().format("YY"))) {
						showErrorMessage("Payment information has expired.");
					} else if (parseInt(item.exprie_month) < parseInt(moment().format("MM"))) {
						showErrorMessage("Payment information has expired.");
					}
				}
				history.push("/subscription-plan");
			}
		}
	};
	const getDataUser = () => {
		makeRequest("get", "user/userInfo").then(({ data }) => {
			if (data.signal) {
				let dataUser = data.data;
				setDataUser(dataUser)
				if (
					(dataUser.is_manager !== 1 && dataUser.is_marker !== 1) ||
					dataUser.is_active !== 1 ||
					dataUser.status !== 1
				) {
					props.logout();
				}
				if (dataUser.cb_id) {
					setUser(true);
					getCBById(dataUser.cb_id, dataUser.is_marker);
					let arrayPermissions = data.data.arrayPermissions;
					localStorage.setItem("x-permission", JSON.stringify(arrayPermissions));
				}
			} else {
				props.logout();
			}
		});
	};
	useEffect(() => {
		if (!user || !settingData) {
			getDataUser();
		} else {
			getCBById(cb_id, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.location.pathname]);

	useEffect(() => {
		setTimeout(() => {
			const paths = props?.location?.pathname.split("/");
			if (paths[1] === "course-builder" && paths[2] === "preview" && !permissionsCourseBuilder) {
				setShowMenu(false);
			} else {
				if (paths[1] !== "subscription-plan") {
					setShowMenu(true);
				} else {
					if (paths[1] === "subscription-plan") {
						setShowMenu(false);
					}
				}
			}
		}, 1000);
	}, [props]);

	const clickOkModal = () => {
		let newList = [];

		let id = listNotify[0].id;

		if (listNotify.length > 1) {
			newList = listNotify.slice(1, listNotify.length);
		} else {
			newList = listNotify.slice(0, 0);
		}

		setListNotify(newList);

		makeRequest("get", `notification/createdistrinotify?notify_id=${id}`)
			.then(() => { })
			.catch((err) => {
				console.log(err);
			});
	};

	const clickCancelModal = () => {
		let newList = [];
		newList = listNotify.slice(0, 0);
		setListNotify(newList);
	};


	const menuNav = MenuConfig?.aside ? MenuConfig?.aside?.items : []


	const getNotificationList = (isDefault) => {
		let payload = {}
		if (pageNoti && !isDefault) {
			payload.page = pageNoti
		}
		makeRequest("get", `notification/list`, payload)
			.then(({ data }) => {
				if (data.signal) {
					if (pageNoti && !isDefault) {
						setNotiList({
							list: notiList.list.concat(data.data.list),
							totalActive: data.data.totalActive,
							total: data.data.total
						})
					} else {
						setNotiList({
							list: data.data.list,
							totalActive: data.data.totalActive,
							total: data.data.total
						})
					}
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const requestNotificationAccess = () => {
		if (typeof window !== 'undefined') {
			Notification.requestPermission().then((permission) => {
				if (permission === 'granted') {
					console.log('Notification permission granted.');
					getFCMToken();
				} else {
					console.log('Unable to get permission to notify.');
				}
			});
		}
	};

	useEffect(() => {
		requestNotificationAccess()
		setPageNoti(0)
		getNotificationList(true)
	}, [props?.location?.pathname, reloadNoti])

	useEffect(() => {
		if (pageNoti && pageNoti !== 0) {
			getNotificationList()
		}
	}, [pageNoti])

	useEffect(() => {
		onMessageListener().then(data => {
			let notificationData = JSON.parse(data.data.data)
			const isShowNoti = notificationData?.receiver_id === dataUser?.id
			if (isShowNoti) {
				setPageNoti(0)
				const noti = notiList.list.length > 0 ? notiList.list.filter((item) => item.id === notificationData.id) : []
				let notificationValue = {}
				if (noti.length > 0) {
					notificationValue = {
						list: notiList.list,
						totalActive: notiList.totalActive,
						total: notiList.total
					}
				} else {
					notificationValue = {
						list: [notificationData].concat(notiList.list),
						totalActive: notificationData.status === 1
							? notiList.totalActive + 1
							: notiList.totalActive,
						total: notiList.total + 1
					}
				}
				setNotiList({ ...notificationValue })
			}
		})
	})

	const findSubMenu = (submenu = []) => {
		return submenu.find((item) => {
			if (item?.pathname) {
				return item.pathname?.find((item) => matchPath(props?.location?.pathname, item.path))
			}
			if (item?.submenu) {
				return item.submenu.find((item) => {
					if (item.pathname) {
						return item.pathname?.find((item) => matchPath(props?.location?.pathname, item.path))
					}
					if (item.submenu) {
						return item.submenu.find((item) => item.pathname?.find((item) => matchPath(props?.location?.pathname, item.path)))
					}
					return false
				})
			}
			return false
		})
	}

	const page = findSubMenu(menuNav)

	const navSubmenu = page?.submenu
		? findSubMenu(page.submenu)
		: {}
	const pageSubmenu = navSubmenu?.submenu ? findSubMenu(navSubmenu.submenu) : navSubmenu
	const pageHeader = page?.pathname
		? page.pathname.find((item) => matchPath(props?.location?.pathname, item.path))
		: {}
	const pageSubmenuHeader = pageSubmenu?.pathname
		? pageSubmenu.pathname.find((item) => matchPath(props?.location?.pathname, item.path))
		: {}

	return selfLayout !== "blank" ? (
		<LayoutInitializer menuConfig={MenuConfig} layoutConfig={LayoutConfig} htmlClassService={htmlClassService}>
			{/* <!-- begin:: Header Mobile --> */}
			<HeaderMobile />
			{/* <!-- end:: Header Mobile --> */}

			<div className="kt-grid kt-grid--hor kt-grid--root">
				{/* <!-- begin::Body --> */}
				<div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
					{/* <!-- begin:: Aside Left --> */}
					{showMenu
						? asideDisplay && (
							<>
								<AsideLeft setting={settingData} />
							</>
						)
						: ""}
					{/* <!-- end:: Aside Left --> */}
					<div
						className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
						id="kt_wrapper"
						style={{ paddingLeft: !showMenu ? 0 : "", margin: !showMenu ? "0 auto" : "" }}
					>
						{/* <!-- begin:: Header READY --> */}

						<Header page={pageHeader} pageSubmenu={pageSubmenuHeader} showMenu={showMenu} notiList={notiList} setPageNoti={setPageNoti} setReloadNoti={setReloadNoti} />
						{/* <!-- end:: Header --> */}

						{/* <!-- begin:: Content --> */}
						<div id="kt_content">
							{/* <!-- begin:: Content Head --> */}
							{subheaderDisplay && <SubHeader />}
							{/* <!-- end:: Content Head --> */}

							{/* <!-- begin:: Content Body --> */}
							{/* TODO: add class to animate  kt-grid--animateContent-finished */}
							<KtContent>{children}</KtContent>
							{/*<!-- end:: Content Body -->*/}
						</div>
						{/* <!-- end:: Content --> */}
						{/* <Footer /> */}
					</div>
				</div>
				{/* <!-- end:: Body --> */}
			</div>
			<QuickPanel />
			<ScrollTop />
			<Modal
				title="Thông báo"
				visible={listNotify && listNotify.length > 0 ? true : false}
				cancelText="Đóng tất cả"
				okText="Đánh dấu đã đọc"
				onCancel={clickCancelModal}
				onOk={clickOkModal}
				width={500}
			>
				<div className="modal-header d-flex justify-content-center">
					<h5 className="heading text-h5">{listNotify && listNotify.length > 0 ? listNotify[0].title : null}</h5>
				</div>
				<div>
					<div>
						<div className="modal-dialog modal-notify modal-info" role="document">
							<div className="modal-content text-center">
								<div className="modal-body">
									<i className="fas fa-bell fa-4x animated rotateIn mb-4" />
									<p>{listNotify && listNotify.length > 0 ? listNotify[0].content : null}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Modal>
			{/* <StickyToolbar /> */}
		</LayoutInitializer>
	) : (
		// BLANK LAYOUT
		<div className="kt-grid kt-grid--ver kt-grid--root">
			<KtContent>{children}</KtContent>
		</div>
	);
}

const mapStateToProps = ({ builder: { layoutConfig } }) => ({
	layoutConfig,
	selfLayout: objectPath.get(layoutConfig, "self.layout"),
	asideDisplay: objectPath.get(layoutConfig, "aside.self.display"),
	subheaderDisplay: objectPath.get(layoutConfig, "subheader.display"),
	desktopHeaderDisplay: objectPath.get(layoutConfig, "header.self.fixed.desktop"),
	contentContainerClasses: "",
});

export default connect(mapStateToProps, auth.actions)(Layout);
